<template>
    <div class="navigation" :class="{ webp: settings.webpSupport }">
        <router-link
            exact
            :to="{ name: pages.HOME_PAGE_NAME }"
            class="no-underline"
        >
            <img
                class="logo"
                src="/static/images/navigation/vigor-logo-small.svg"
            />
        </router-link>

        <div id="nav-basic">
            <template v-for="item in navigationItems">
                <a
                    v-if="item.outside"
                    :key="item.label"
                    target="_blank"
                    rel="noopener noreferrer"
                    :href="item.link"
                    class="nav-big no-underline"
                >
                    <span class="nav-item">
                        <span
                            class="nav-item-label"
                            :class="{ current: item.link === current }"
                        >
                            {{ item.label }}
                        </span>
                    </span>
                </a>
                <router-link
                    v-else
                    :key="item.label"
                    exact
                    :to="{ name: item.link }"
                    class="nav-big no-underline"
                >
                    <span class="nav-item">
                        <span
                            class="nav-item-label"
                            :class="{ current: item.link === current }"
                            >{{ item.label }}</span
                        >
                    </span>
                </router-link>
            </template>
            <template v-if="!user && !showHamburgerContent && window.w > 1150">
                <a :href="login" class="nav-login no-underline">
                    <span class="nav-item login not-logged">
                        <span class="login-text">Log in</span>
                        <UserIcon width="27" fill="#ed2024" />
                    </span>
                </a>
            </template>

            <template v-if="user && !showHamburgerContent && window.w > 1150">
                <a class="nav-login no-underline" @click="toggleMenu">
                    <span
                        class="nav-item login logged"
                        :class="{ opened: showUserMenu }"
                    >
                        <DoubleArrowIcon
                            width="12"
                            fill="#ed2024"
                            :class="{ rotated: showUserMenu }"
                        />
                        <span class="login-text">{{ user.username }}</span>
                        <UserIcon width="27" fill="#ed2024" />
                    </span>
                </a>
                <div v-if="showUserMenu" class="user-menu">
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        :href="profile"
                        class="user-item no-underline"
                    >
                        <span>
                            Your account
                        </span>
                    </a>
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        :href="logout"
                        class="user-item no-underline"
                    >
                        <span>
                            Log out
                        </span>
                    </a>
                </div>
            </template>
        </div>

        <!-- PLAY MENU SECTION START -->
        <div class="play-now" @click="togglePlayMenu">
            <img
                v-if="window.w > 1150"
                src="/static/images/navigation/play-now-banner.png"
            />
            <span class="play-now__text" style="display: flex; gap: 10px;">
                <DoubleArrowIcon
                    width="17"
                    :class="{ rotated: showPlayContent }"
                />
                <span>WISHLIST ON STEAM</span>
            </span>
        </div>
        <template v-if="showPlayContent">
            <div class="play-menu">
                <div class="content">
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://store.steampowered.com/app/2818260/Vigor/"
                        class="play-item no-underline"
                    >
                        <span>
                            STEAM
                        </span>
                    </a>
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.microsoft.com/store/apps/BQL8L17RV09Z"
                        class="play-item no-underline"
                    >
                        <span>
                            XBOX ONE
                        </span>
                    </a>
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.nintendo.com/games/detail/vigor-switch/"
                        class="play-item no-underline"
                    >
                        <span>
                            NINTENDO SWITCH
                        </span>
                    </a>
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://store.playstation.com/product/EP2601-CUSA13066_00-VIGORLEADSKU0001"
                        class="play-item no-underline"
                    >
                        <span>
                            PLAYSTATION
                        </span>
                    </a>
                </div>
            </div>
        </template>
        <!-- PLAY MENU SECTION END -->

        <!-- HAMBURGER MENU SECTION START -->
        <img
            id="nav-hamburger"
            src="/static/images/navigation/hamburger.svg"
            @click="toggleHamburgerMenu"
        />
        <div
            id="nav-hamburger-content"
            :class="{ show: showHamburgerContent, webp: settings.webpSupport }"
        >
            <div class="close" @click="toggleHamburgerMenu">
                <img src="/static/images/close.svg" />
            </div>
            <div class="content">
                <div class="items">
                    <template v-for="(item, index) in navigationItems">
                        <a
                            v-if="item.outside"
                            :key="item.label"
                            target="_blank"
                            rel="noopener noreferrer"
                            :href="item.link"
                            class="no-underline"
                        >
                            <span
                                class="nav-item"
                                :class="{
                                    last: index == navigationItems.length - 1,
                                }"
                                @click="toggleHamburgerMenu"
                            >
                                <span
                                    class="nav-item-label"
                                    :class="{ current: item.link === current }"
                                >
                                    {{ item.label }}
                                </span>
                            </span>
                        </a>
                        <router-link
                            v-else
                            :key="item.label"
                            exact
                            :to="{ name: item.link }"
                            class="no-underline"
                        >
                            <span
                                class="nav-item"
                                :class="{
                                    last: index == navigationItems.length - 1,
                                }"
                                @click="toggleHamburgerMenu"
                            >
                                <span
                                    class="nav-item-label"
                                    :class="{ current: item.link === current }"
                                    >{{ item.label }}</span
                                >
                            </span>
                        </router-link>
                    </template>
                    <template
                        v-if="
                            !user && (!showHamburgerContent || window.w <= 1150)
                        "
                    >
                        <a :href="login" class="nav-login no-underline">
                            <span class="nav-item login not-logged">
                                <span class="login-text">Log in</span>
                                <UserIcon width="27" fill="#ed2024" />
                            </span>
                        </a>
                    </template>
                    <template
                        v-if="
                            user && (showHamburgerContent || window.w <= 1150)
                        "
                    >
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            :href="profile"
                            class="nav-login no-underline"
                        >
                            <span class="nav-item login logged">
                                <span class="login-text">
                                    Your Account
                                </span>
                                <UserIcon width="27" fill="#ed2024" />
                            </span> </a
                        ><a
                            target="_blank"
                            rel="noopener noreferrer"
                            :href="logout"
                            class="nav-login no-underline"
                        >
                            <span class="nav-item login logged">
                                <span class="login-text">
                                    Log Out
                                </span>
                                <UserIcon width="27" fill="#ed2024" />
                            </span> </a
                    ></template>
                </div>
            </div>
        </div>
        <!-- HAMBURGER MENU SECTION END -->
    </div>
</template>

<script>
import * as pages from '@/routes/pageCodes';
import { mapGetters } from 'vuex';

import DoubleArrowIcon from 'Images/navigation/double_arrow.svg?inline';
import UserIcon from 'Images/navigation/user.svg?inline';

const navigationItems = [
    { label: 'About', link: pages.ABOUT_PAGE_NAME, outside: false },
    { label: 'News', link: pages.NEWS_PAGE_NAME, outside: false },
    { label: 'Updates', link: pages.UPDATES_PAGE_NAME, outside: false },
    { label: 'Community', link: pages.COMMUNITY, outside: false },
    {
        label: 'Merch',
        link: 'https://store.bistudio.com/collections/vigor-apparel',
        outside: true,
    },
];

const login = '/api/auth/login';
const logout = '/api/auth/logout';
const profile = 'https://accounts.bistudio.com/profile';

export default {
    name: 'Navigation',
    components: { DoubleArrowIcon, UserIcon },
    data() {
        return {
            pages,
            login,
            logout,
            profile,
            navigationItems,
            showHamburgerContent: false,
            showPlayContent: false,
            showUserMenu: false,
            current: pages.HOME_PAGE_NAME,
            window: { w: window.innerWidth, h: window.innerHeight },
        };
    },
    watch: {
        $route(to) {
            this.current = to.name;
        },
    },
    methods: {
        togglePlayMenu() {
            this.showPlayContent = !this.showPlayContent;
        },
        toggleHamburgerMenu() {
            this.showHamburgerContent = !this.showHamburgerContent;
        },
        toggleMenu() {
            this.showUserMenu = !this.showUserMenu;
        },
        windowResize() {
            this.window = { w: window.innerWidth, h: window.innerHeight };
        },
    },
    computed: {
        ...mapGetters('user', {
            user: 'getUser',
        }),
        ...mapGetters('settings', {
            settings: 'settings',
        }),
    },
    mounted() {
        this.current = this.$route.name;
        window.addEventListener('resize', this.windowResize);
    },
    destroyed() {
        window.removeEventListener('resize', this.windowResize);
    },
};
</script>

<style scoped lang="scss">
@import '../../styles/colors.scss';
@import '../../styles/global.scss';

.navigation {
    width: 100vw;
    height: 59px;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;

    z-index: 999;
    background-image: url('/static/images/navigation/navigation-bg-edge.png');
    background-repeat: repeat-x;

    &.webp {
        background-image: url('/static/images/navigation/navigation-bg-edge.webp');
    }

    .logo {
        height: 34px;
        margin: 0 30px;

        &:hover {
            opacity: 0.5;
        }
    }

    .nav-item {
        color: $light;
        text-transform: uppercase;

        .nav-item-label {
            opacity: 0.5;
            display: inline;
            vertical-align: top;

            &:hover {
                opacity: 1;
            }

            &.current {
                opacity: 1;
            }
        }
    }

    .user-menu {
        display: block;

        .user-item {
            display: block;
            width: 190px;
            height: 20px;
            padding: 15px 15px 15px 25px;
            background-color: #252525;
            text-align: left;

            span {
                color: $red;
                font-weight: 700;
                text-transform: uppercase;
                font-size: 16px;
                font-weight: 700;
                letter-spacing: 0.32px;
            }

            &:hover {
                span {
                    opacity: 0.8;
                }
            }
        }
    }

    #nav-basic {
        width: 100%;
        position: relative;
        display: block;
        flex: 1;

        .nav-item {
            padding: 17px 35px;
            font-size: 16px;
            font-weight: 700;
            display: inline-flex;
            align-items: center;
        }

        .login {
            color: $red;
            display: inline-flex;
            align-items: center;
            padding-right: 0;

            .login-text {
                margin: 0 5px;
            }

            svg {
                transition: all 0.25s ease-in-out;
            }

            svg.rotated {
                transform: rotate(180deg);
            }
        }

        .logged {
            cursor: pointer;
        }

        .user-menu {
            position: absolute;
            top: 53px;
            left: 640px;

            .user-item {
                margin: 5px 0;
            }
        }
    }

    #nav-hamburger {
        height: 30px;
        width: 53px;
        padding: 10px 25px 10px 15px;
        float: right;
        vertical-align: top;
        display: inline-block;
        cursor: pointer;
        opacity: 1;

        &:hover {
            opacity: 0.8;
        }
    }

    #nav-hamburger-content {
        height: 100vh;
        width: 611px;
        max-width: 100vw;
        position: absolute;
        top: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.8);
        z-index: 102;
        margin-right: -611px;

        // transition: margin 1s ease-in-out;

        &.show {
            margin-right: 0;
        }

        .close {
            padding: 10px 25px;
            position: absolute;
            top: 0;
            right: 0;
            cursor: pointer;
            opacity: 1;

            img {
                height: 45px;
                vertical-align: top;
            }

            .text {
                margin: 6px 0;
                color: $light;
                font-size: 20px;
                font-weight: 700;
                text-transform: uppercase;
                letter-spacing: 0.48px;
                display: inline-block;
                vertical-align: top;
            }
            &:hover {
                opacity: 0.8;
            }
        }

        .content {
            margin: 70px 0 0 30px;

            .items {
                max-height: calc(100vh - 190px);
                overflow-y: auto;
                padding-bottom: 50px;
                overflow-y: -moz-scrollbars-none;
            }

            ::-webkit-scrollbar {
                display: none;
            }

            -ms-overflow-style: none;

            .nav-item {
                padding: 15px 20px;
                margin-bottom: 10px;
                display: block;
                font-size: 22px;
                font-weight: 700;
                text-transform: uppercase;
                background-color: $dark-lighter;
                color: $light;

                .nav-item-label {
                    border: none;
                    opacity: 1;
                }
            }
            .login {
                color: $red;
                display: flex;
                align-items: center;

                .login-text {
                    margin: 0 5px;
                }
            }
        }
    }

    .play-now {
        position: relative;
        cursor: pointer;

        &:hover {
            filter: brightness(80%);
        }

        img {
            @media (max-width: 1150px) {
                display: none;
            }
        }

        svg {
            fill: $light;
            transition: all 0.25s ease-in-out;

            @media (max-width: 1150px) {
                fill: $red;
            }
        }

        svg.rotated {
            transform: rotate(180deg);
        }
        .play-now__text {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: $light;
            font-size: 13px;
            font-weight: 700;
            text-transform: uppercase;

            @media (max-width: 1150px) {
                position: relative;
                top: 0;
                transform: translate(0);
                left: 0;
                color: $red;
            }
        }
        @media (max-width: 1150px) {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    .play-menu {
        max-width: 185px;
        width: 100%;
        position: absolute;
        top: 50px;
        right: 0;

        .content {
            margin-top: 15px;

            .play-item {
                display: block;

                width: 100%;
                height: 20px;
                padding: 15px;
                background-color: $red;
                text-align: left;
                margin-top: 5px;
                span {
                    color: $light;
                    font-weight: 700;
                    font-size: 16px;
                    font-weight: 700;
                    letter-spacing: 0.32px;
                    text-transform: uppercase;
                }

                &:hover {
                    filter: brightness(80%);
                }
            }
        }
    }

    @media (min-width: 1151px) {
        #nav-hamburger,
        #nav-hamburger-content {
            display: none;
        }
    }

    @media (max-width: 1150px) {
        .nav-big {
            display: none;
        }
    }

    @media (max-width: 390px) {
        #nav-hamburger {
            padding: 10px;
        }

        .logo {
            margin: 10px;
            margin-right: 0;
        }
    }
}
</style>
